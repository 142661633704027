// Row.tsx
import React, { ReactNode, CSSProperties } from 'react'

interface RowProps {
    children: ReactNode
    className?: string
    inlineStyle?: CSSProperties // Add an optional inline style prop
}

export const Row: React.FC<RowProps> = ({
    children,
    className,
    inlineStyle,
}) => {
    return (
        <div className={`flex flex-row ${className}`} style={inlineStyle}>
            {children}
        </div>
    )
}
