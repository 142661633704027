import React, { useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { Text } from '../../../base/Text';
import { Button } from '../../../base/Button';
import { Column } from '../../../layout/Col';
import { Row } from '../../../layout/Row';
import { TextBackground } from '../../../base/TextBackground';
import { AddNewUnit } from './component/AddNewUnit';
import { AcceptAll } from './component/AcceptAll';
import { DeclineAll } from './component/DeclineAll';
import { RmaButtons } from './RmaButtons';

export const Head: React.FC<{ rmaItemsData: any; refetchData: () => void }> = ({ rmaItemsData, refetchData }) => {
  const [showAddUnitModal, setShowAddUnitModal] = useState(false);
  const [showAcceptAllModal, setShowAcceptAllModal] = useState(false);
  const [showDeclineAllModal, setShowDeclineAllModal] = useState(false);
  const [item, setItem] = useState(null);

  const openAddUnitModal = (item) => {
    setItem(item);
    setShowAddUnitModal(true);
  }
console.log(rmaItemsData)
  const openAcceptAllModal = (item) => {
    setItem(item);
    setShowAcceptAllModal(true);
  }

  const openDeclineAllModal = (item) => {
    setItem(item);
    setShowDeclineAllModal(true);
  }

  const handleCloseModal = () => {
    refetchData()
    setShowAddUnitModal(false);
    setShowAcceptAllModal(false);
    setShowDeclineAllModal(false);

  }
  return (
    <Flex className="py-2">
      <Column>
        <Row className="gap-x-4 mb-5 items-center ">
          <TextBackground backgroundColor="b9blue">
            <Row className="gap-x-5 items-center justify-center">
              <Text colorV="white" weightV="bold" variant="title">
                Customer
              </Text>
              <div className="bg-white rounded-lg px-4 py-1">
                <Text colorV="blue" weightV="normal" variant="title">
                  {rmaItemsData?.customer?.companyName}
                </Text>
              </div>
            </Row>
          </TextBackground>
          <TextBackground backgroundColor="b9blue">
            <Row className="gap-x-5 items-center justify-center">
              <Text colorV="white" weightV="bold" variant="title">
                RMA number
              </Text>
              <div className="bg-white rounded-lg px-4 py-1">
                <Text colorV="blue" weightV="normal" variant="title">
                  {rmaItemsData?.rmaNumber}
                </Text>
              </div>
            </Row>
          </TextBackground>

          <Button
            backgroundColor="lgblue"
            onClick={() => openAddUnitModal(rmaItemsData)}
          >
            <Text colorV="white" variant="title" weightV="normal">
              Add unit to report
            </Text>
          </Button>
          <Button
            backgroundColor="lgblue"
            onClick={() => openAcceptAllModal(rmaItemsData)}
          >
            <Text colorV="white" variant="title" weightV="normal">
              Accept all
            </Text>
          </Button>
          <Button
            backgroundColor="yellow"
            onClick={() => openDeclineAllModal(rmaItemsData)}
          >
            <Text colorV="white" variant="title" weightV="normal">
              Decline all
            </Text>
          </Button>
        </Row>
      </Column>

      {showAddUnitModal && (
        <AddNewUnit onClose={handleCloseModal}  />
      )}
      {showAcceptAllModal && (
        <AcceptAll onClose={handleCloseModal} item={rmaItemsData} />
      )}
      {showDeclineAllModal && (
        <DeclineAll onClose={handleCloseModal} item={item} />
      )}
    </Flex>
  );
}
