import React, { useState, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { PrimaryCard } from '../../components/compound/Page/PrimaryCard';
import { Text } from '../../components/base/Text';
import { useLocation } from 'react-router-dom';

interface SubNavItem {
  text: string;
  to: string;
}

interface SubNavbarProps {
  initialNavItems: SubNavItem[];
  heading?: string;
  className?: string;
}

export const SubNavbar: React.FC<SubNavbarProps> = ({
  initialNavItems,
  heading,
  className,
}) => {
  const [navItems] = useState<SubNavItem[]>(initialNavItems);
  const location = useLocation();
  const currentPath = location.pathname;
  const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(null);

  const navItemsStyle: CSSProperties = {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
  };

  const navItemStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    fontSize: '18px',
    cursor: 'pointer',
    color: 'white',
  };

  const handleClick = (index: number) => {
    setLastClickedIndex(index);
  };
  const pathSegments = currentPath.split('/');
  const slicedPath = `/${pathSegments.slice(1, 3).join('/')}`;
  const isPathMatched = navItems.some((item) => slicedPath === item.to);
  const activateLastClicked = !isPathMatched && lastClickedIndex !== null;
  return (
    <PrimaryCard
      style={{ width: 210 }}
      className={`px-3 ${className} shadow-none rounded`}
    >
      {heading && (
        <div className="mb-5">
          <Text colorV="blue" variant="heading2" weightV="normal">
            {heading}
          </Text>
        </div>
      )}

      <ul style={navItemsStyle} className="nav-items">
        {navItems.map((item: SubNavItem, index: number) => (
          <li
            key={index}
            style={{
              ...navItemStyle,
              color:
                (slicedPath === item.to && 'blue') ||
                (activateLastClicked && lastClickedIndex === index && 'blue') ||
                'white',
              marginBottom: index === navItems.length - 1 ? '0' : '20px',
            }}
            className="nav-item"
          >
            <Link
              to={item.to}
              style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => handleClick(index)}
            >
              <span
                style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor:
                    (slicedPath === item.to && '#3B65F6') ||
                    (activateLastClicked && lastClickedIndex === index && '#3B65F6') ||
                    '#112D56',
                  borderRadius: '50%',
                  marginRight: '8px',
                }}
              ></span>
              <Text
                colorV={
                  (slicedPath === item.to && 'mid-blue') ||
                  (activateLastClicked && lastClickedIndex === index && 'mid-blue') ||
                  'blue'
                }
                variant="title"
                weightV='normal'
                style={{ paddingLeft: '8px' }}
              >
                {item.text}
              </Text>
            </Link>
          </li>
        ))}
      </ul>
    </PrimaryCard>
  );
};
