import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Icon } from '../../../../base/Icon';
import { MyIcon } from '../../../../base/MyIcon';
import { SearchBar } from '../../../../base/Searchbar';
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../base/Text';

type FilterProps = {
    onDataChange: (data: any) => void;
    itemsData: any[];
    refetch: () => void;
};

export const Filter: React.FC<FilterProps> = ({ onDataChange, itemsData, refetch }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [customer, setCustomer] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleButtonClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCloseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation(); // Stop the event propagation to prevent closing the dropdown
        setShowDropdown(false);
    };

    const handleDocumentClick = (e: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setShowDropdown(false);
        }
    };

    const handleOptionsChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedCustomer = e.target.value;
            if (selectedCustomer === '') {
                setCustomer('');
                refetch();
            } else {
                setCustomer(selectedCustomer);
                const filteredData = itemsData.filter(item => item.customer?._id === selectedCustomer);
                onDataChange(filteredData);
            }
        },
        [itemsData, onDataChange, refetch]
    );

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showDropdown]);

    const uniqueCustomerIds = Array.from(new Set(itemsData?.map(item => item.customer?._id)));
    const uniqueCustomers = uniqueCustomerIds?.map(id => itemsData.find(item => item.customer?._id === id)?.customer);

    return (
        <Flex className="relative">
            <RowAlignCenter className="gap-x-5">
                <div ref={dropdownRef} className="relative ">
                    <div
                        onClick={handleButtonClick}
                        className={`cursor-pointer p-2 px-3 rounded-lg ${customer === '' ? 'bg-b9midblue' : 'bg-b9green'}`}
                    >
                        <div className="flex items-center gap-x-4 w-28">
                            <MyIcon name="filter" color="white" />
                            <Text colorV="white" variant="title" weightV="normal">
                                Filter
                            </Text>
                        </div>
                    </div>
                    {showDropdown && (
                        <div className="absolute w-72 top-[calc(100% + 5px)] right-10 mt-2 w-full rounded shadow-lg z-40">
                            <div className=' bg-white rounded-md pt-1'>
                                <div className="flex justify-end pr-1 ">
                                    <span onClick={handleCloseIconClick} className='cursor-pointer' >
                                        <Icon name="cross" color="black" size={18} />
                                    </span>
                                </div>
                                <Column className="p-4" >
                                    <RowAlignCenterSpaced className='justify-center'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Customer</Text>
                                        <select
                                            value={customer}
                                            onChange={handleOptionsChange}
                                            className=" mb-2 py-1 w-36"
                                        >
                                            <option value="">Select customer</option>
                                            {uniqueCustomers?.map(customer => (
                                                <option key={customer?._id} value={customer?._id}>
                                                    {customer?.companyName}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                </Column>
                            </div>
                        </div>
                    )}
                </div>
            </RowAlignCenter>
            {showDropdown && (
                <div
                    className="fixed inset-0 bg-black opacity-30 z-0"
                    onClick={handleButtonClick}
                ></div>
            )}
        </Flex>
    );
};
