import React, { useState } from 'react'
import { Flex } from '../../../layout/Flex'
import { Text } from '../../../base/Text'
import { Button } from '../../../base/Button'
import { Column } from '../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Row } from '../../../layout/Row'
import { SalesInventory } from './SalesInventory/SalesInventory'
import { SparepartsInventory } from './SparepartsInventory/SparepartsInventory'

export default function Head() {
    const [activeTab, setActiveTab] = useState('Manage sales SKU')

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className="py-5 w-full">
            <Column className='w-full'>
                <Row className="gap-x-5 w-full">
                    <Button
                        onClick={() => handleTabClick('Manage sales SKU')}
                        backgroundColor={
                            activeTab === 'Manage sales SKU' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Manage sales SKU'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Manage sales SKU'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Sale inventory
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Spare parts inventory')}
                        backgroundColor={
                            activeTab === 'Spare parts inventory'
                                ? 'blue'
                                : 'grey'
                        }
                        className={
                            activeTab === 'Spare parts inventory'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Spare parts inventory'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Spare parts inventory
                        </Text>
                    </Button>
                </Row>
                <RowAlignCenterSpaced className="mt-5">
                    {activeTab === 'Manage sales SKU' && <SalesInventory />}
                    {activeTab === 'Spare parts inventory' && (
                        <SparepartsInventory />
                    )}
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    )
}
