import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { Button } from '../../../../base/Button'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../utils/auth'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { TextArea } from '../../../../base/Textarea'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../hooks/useFetch'
import { TextBackground } from '../../../../base/TextBackground'
import { useEdit } from '../../../../../hooks/useEdit'
import { usePost } from '../../../../../hooks/usePost'
import JsBarcode from 'jsbarcode'
import { generatePDF } from '../../../../shared/GenerateLabel'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const ImeiSearch = ({ data, handleBack }: any) => {
    const [grade, setGrade] = useState('')
    const [color, setColor] = useState('')
    const [battery, setBattery] = useState('')
    const [sku, setSku] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [skuId, setSkuId] = useState<string>('')
    const [skuColor, setSkuColor] = useState<any>([])
    const [loading, setLoading] = useState(false);
    const [savePrintLoading, setSavePrintLoading] = useState(false)
    const [itemGrade, setItemGrade] = useState<any>()
    const barCode = useRef<HTMLDivElement>(null);
    console.log({ data });
    const navigate = useNavigate()
    const auth = useAuth()

    const token = auth?.user?.token?.token
    const { sendEditRequest } = useEdit()
    const { getRequest: getSaleInventorySkuItems, data: itemsData } = useFetch(
        `${baseUrl}/sale-sku/get-sale-inventory-sku`
    )
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels()
        if (!itemsData) {
            getSaleInventorySkuItems();
        }
        let localColors;
        if (itemsData) {
            localColors = itemsData
                .filter((item) => item.modelName.modelName === data.model.modelName
                    && item.storage.includes(data.storage)
                    && item.vat == data.vat)
                .map((item) => item.color);
            const uniqueColors = Array.from(new Set(localColors));
            setSkuColor(uniqueColors);
            const localGrade = itemsData
                .filter((item) =>
                    item.modelName.modelName === data.model.modelName &&
                    item.storage.includes(data.storage) &&
                    item.vat === data.vat
                )
                .map((item) => item.grade);
            const uniqueArray = Array.from(new Set(localGrade.map(item => item._id))).map(_id => localGrade.find(obj => obj._id === _id));
            setItemGrade(uniqueArray);
        }
    }, [data.model.modelName, data.storage, data.vat, getLabels, getSaleInventorySkuItems, itemsData])

    const handleBatteryChange = (newValue: string) => {
        setBattery(newValue)
    }
    useEffect(() => {
        if (data) {
            setColor(data?.color)
            setBattery(data?.battery)
            setGrade(data?.grade?._id)
            setSku(data?.sku?.sku)
            setSkuId(data?.sku?._id)
        }
    }, [data])
    
    const handleColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newColor = event.target.value;
        setColor(newColor);
        updateSku(newColor, grade);
    };

    const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newGradeId = event.target.value;
        setGrade(newGradeId);
        updateSku(color, newGradeId);
    };

    const updateSku = (selectedColor: string, selectedGradeId: string) => {
        if (selectedColor && selectedGradeId) {
            const matchingItem = itemsData?.find(
                (item) => item.modelName.modelName === data.model.modelName &&
                    item.storage.includes(data.storage) &&
                    item.color === selectedColor &&
                    item.grade._id === selectedGradeId &&
                    item.vat === data.vat
            );

            if (matchingItem) {
                setSku(matchingItem.sku);
                setSkuId(matchingItem._id);
            } else {
                setSku("")
                setSkuId("")
            }
        }
    };

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    const handleSave = useCallback(async () => {
        const parsedBattery = parseFloat(battery);
        if (isNaN(parsedBattery) || parsedBattery > 100) {
            alert('Enter a valid battery percentage below 100');
            return;
        }
        if (sku === '') {
            alert('Make sku for this imei')
            return
        }
        setLoading(true);
        await sendEditRequest(
            `${baseUrl}/item/single-grading/${data?._id}`,
            {
                battery,
                id: data?._id,
                skuId,
                comment,
                grade,
                color,
            },
        )
            .then(() => {
                toast.success('Saved', { hideProgressBar: false })
                addNotification({
                    message: 'Record Updated Successfully',
                    type: 'success',
                })
                setBattery('');
                setSku('')
                setGrade('');
                setComment('')
                setColor('')
                setLoading(false);
                handleBack()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, { hideProgressBar: false })
            })
        setLoading(false);
    }, [battery, color, comment, data?._id, grade, handleBack, sendEditRequest, sku, skuId])

    const handleSavePrint = useCallback(async () => {
        const parsedBattery = parseFloat(battery);
        if (isNaN(parsedBattery) || parsedBattery > 100) {
            alert('Enter a valid battery percentage below 100');
            return;
        }
        if (sku === '') {
            alert('Make sku for this imei')
            return
        }
        setSavePrintLoading(true);
        await sendEditRequest(
            `${baseUrl}/item/single-grading/${data?._id}`,
            {
                battery,
                id: data?._id,
                skuId,
                comment,
                grade,
                color,
            },
        )
            .then((response) => {
                console.log({ response });
                toast.success('Saved', { hideProgressBar: false })
                addNotification({
                    message: 'Record Updated Successfully',
                    type: 'success',
                })
                setBattery('');
                setSku('')
                setGrade('');
                setComment('')
                setColor('')
                setLoading(false);
                JsBarcode('#barcode', `${response?.data?.imei}`, {
                    lineColor: 'black',
                    textAlign: 'center',
                    width: 1,
                    background: 'transparent',
                    height: 40,
                    displayValue: false,
                    xmlDocument: document,
                });
                console.log({response});
                const item = { item: response?.data }
                generatePDF(item, labelsData, barCode)
                handleBack()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, { hideProgressBar: false })
                console.error(`Error: ${error}`)
            })
        setSavePrintLoading(false);
    }, [battery, color, comment, data?._id, grade, handleBack, labelsData, sendEditRequest, sku, skuId])

    return (
        <>
            <Column className="w-1/2 gap-y-4">
                <Text colorV="blue" weightV="bold" variant="heading">
                    Grading
                </Text>
                <PrimaryCard className="p-5   items-center text-center">
                    <Column className="gap-y-4 text-left">
                        <RowAlignCenterSpaced className="">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                IMEI/ Serial number
                            </Text>
                            <TextBackground className='w-72' backgroundColor='base01'>
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.imei || ''}
                                </Text>
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Model
                            </Text>
                            <TextBackground className='w-72' backgroundColor='base01'>
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.model?.modelName || ''}
                                </Text>
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Storage
                            </Text>
                            <TextBackground className='w-72' backgroundColor='base01'>
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.storage || ''}
                                </Text>
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                VAT
                            </Text>
                            <TextBackground className='w-72' backgroundColor='base01'>
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.vat || ''}
                                </Text>
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Battery
                            </Text>
                            <TextField
                            backgroundColor='white'
                                className='w-72'
                                value={battery}
                                onChange={handleBatteryChange}
                                placeholder="Enter within 100"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Color
                            </Text>
                            <div style={{ width: '18rem', }}>
                                <select
                                    id="color"
                                    value={color}
                                    onChange={handleColorChange}
                                    className="w-full hover:cursor-pointer text-sm bg-white   py-2 px-1"                                >
                                    <option>Select</option>
                                    {skuColor?.map((color: any) => (
                                        <option key={color} value={color}>
                                            {color}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Grade
                            </Text>
                            <div style={{ width: '18rem' }}>
                                <select
                                    id="grade"
                                    value={grade}
                                    onChange={handleGradeChange}
                                    className="w-full hover:cursor-pointer text-sm bg-white   py-2 px-1"                                >
                                    <option>Select</option>
                                    {itemGrade?.map((grade: any) => (
                                        <option key={grade} value={grade._id}>
                                            {grade.grade}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                SKU
                            </Text>
                            <TextBackground className='w-72' backgroundColor='base01'>
                                {sku ? (
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {sku}
                                    </Text>
                                ) : (
                                    <Text
                                        colorV="red"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        No SKU found
                                    </Text>
                                )}
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Comment
                            </Text>
                            <TextArea
                            backgroundColor='white'
                                className='w-72'
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder="Enter comment"
                                height="100px" // You can adjust the height here
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenter className="gap-x-4  mt-5">
                            <Button
                                className='w-32'
                                onClick={handleBack}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Back
                                </Text>
                            </Button>

                            <Button
                                disabled={loading}
                                className='w-32'
                                onClick={handleSave}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {loading ? "Saving.." : "Save"}
                                </Text>
                            </Button>
                            <Button
                                className='w-36'
                                onClick={handleSavePrint}
                                backgroundColor="blue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Save & Print
                                </Text>
                            </Button>
                        </RowAlignCenter>
                    </Column>
                </PrimaryCard>
                <div ref={barCode} className=" hidden">
                    <svg id="barcode"></svg>
                </div>
            </Column>
        </>
    )
}
