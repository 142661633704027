import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MyIcon } from '../../../../base/MyIcon';
import { SearchBar } from '../../../../base/Searchbar';
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { useFetch } from '../../../../../hooks/useFetch';
import { toast } from 'react-toastify';
import { usePost } from '../../../../../hooks/usePost';
import { Icon } from '../../../../base/Icon';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';

const baseUrl = process.env.REACT_APP_baseUrl;

type FilterProps = {
    onDataChange: (data: any) => void;
    itemsData: any
};

export const Filter: React.FC<FilterProps> = ({ onDataChange, itemsData }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleButtonClick = useCallback(() => {
        setShowDropdown(prevShowDropdown => !prevShowDropdown);
    }, []);

    const handleLocalOptionSelect = useCallback((value: string, key: string) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [key]: value
        }));
    }, []);

    const handleOptionsChange = useCallback((options: Record<string, string>) => {
        console.log({ itemsData });
        const filteredData = itemsData.filter(item => {
            if (options['Model'] && item.modelDetails !== options.Model) {
                return false;
            }
            // Check if color filter is applied and item's color does not match
            if (options['Color'] && item.color !== options.Color) {
                return false;
            }
            // Check if VAT filter is applied and item's VAT does not match
            if (options['Vat'] && item.vat !== options.Vat) {
                return false;
            }
            // Check if storage filter is applied and item's storage does not match
            if (options['Storage'] && item.storage !== options.Storage) {
                return false;
            }
            // Check if SKU filter is applied and item's SKU does not match
            if (options['Sku'] && item.sku !== options.Sku) {
                return false;
            }
            if (options['Grade'] && item?.grade?.grade !== options.Grade) {
                return false;
            }
            return true;
        });
        onDataChange(filteredData);
    }, [itemsData, onDataChange]);


    const handleSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        const { value } = e.target;
        handleLocalOptionSelect(value, key);
        handleOptionsChange({ ...selectedOptions, [key]: value });
    }, [handleLocalOptionSelect, handleOptionsChange, selectedOptions]);


    const handleCloseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation(); // Stop the event propagation to prevent closing the dropdown
        setShowDropdown(false);
    };

    const getUniqueValues = (itemsData: any[], key: string) => {
        const uniqueValues = new Set(itemsData?.map(item => item[key]));
        return Array.from(uniqueValues);
    };
    const getUniqueGradeValues = (itemsData: any[]) => {
        const uniqueGrades = new Set(itemsData?.map(item => item?.grade?.grade));
        return Array.from(uniqueGrades).filter(Boolean); // filter out any falsy values such as undefined or null
    };
    // Inside the Filter component:
    const uniqueModels = getUniqueValues(itemsData, 'modelDetails');
    const uniqueStorages = getUniqueValues(itemsData, 'storage');
    const uniqueColors = getUniqueValues(itemsData, 'color');
    const uniqueSkus = getUniqueValues(itemsData, 'sku');
    const uniqueGrades = getUniqueGradeValues(itemsData);
    console.log({ uniqueGrades });

    return (
        <Flex>
            <RowAlignCenter className="gap-x-5">
                {/* <SearchBar width="400px" /> */}
                <div ref={dropdownRef} className="relative ">
                    <div onClick={handleButtonClick} className={`cursor-pointer p-2 rounded-lg w-32 ${Object.values(selectedOptions).every(value => value === '') ? 'bg-b9midblue' : 'bg-b9green'}`}>
                        <div className="flex items-center gap-x-4">
                            <MyIcon name="filter" color="white" />
                            <Text colorV="white" variant="title" weightV="normal">
                                Filter
                            </Text>
                        </div>
                    </div>
                    {showDropdown && (
                        <div className="absolute w-72 top-[calc(100% + 5px)] left-0 mt-2 w-full rounded shadow-lg z-40">
                            <div className=' bg-white rounded-md pt-1'>
                                <div className="flex justify-end pr-1 ">
                                    <span onClick={handleCloseIconClick} className='cursor-pointer' >
                                        <Icon name="cross" color="black" size={18} />
                                    </span>
                                </div>
                                <Column className="p-4 gap-y-3">
                                    <RowAlignCenterSpaced className='gap-x-5'>
                                        <Text colorV='blue' variant='body' weightV='bold' >SKU</Text>
                                        <select
                                            value={selectedOptions?.['Sku']}
                                            onChange={(e) => handleSelectChange(e, 'Sku')}
                                            className="w-40 mb-2 "
                                        >
                                            <option value=''>Select SKU</option>
                                            {uniqueSkus?.map((sku: any) => (
                                                <option key={sku} value={sku}>
                                                    {sku}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='gap-x-5'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Model</Text>
                                        <select
                                            value={selectedOptions?.['Model']}
                                            onChange={(e) => handleSelectChange(e, 'Model')}
                                            className="mb-2  w-40"
                                        >
                                            <option value=''>select model</option>
                                            {uniqueModels?.map((model: any) => (
                                                <option key={model} value={model}>
                                                    {model}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='gap-x-5'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Storage</Text>
                                        <select
                                            value={selectedOptions?.['Storage']}
                                            onChange={(e) => handleSelectChange(e, 'Storage')}
                                            className="w-40 mb-2 "
                                        >
                                            <option value=''>Select storage</option>
                                            {uniqueStorages?.map((storage: any) => (
                                                <option key={storage} value={storage}>
                                                    {storage}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='gap-x-5'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Color</Text>
                                        <select
                                            value={selectedOptions?.['Color']}
                                            onChange={(e) => handleSelectChange(e, 'Color')}
                                            className="w-40 mb-2 "
                                        >
                                            <option value=''>Select color</option>
                                            {uniqueColors?.map((color: any) => (
                                                <option key={color} value={color}>
                                                    {color}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='gap-x-5'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Grade</Text>
                                        <select
                                            value={selectedOptions?.['Grade']}
                                            onChange={(e) => handleSelectChange(e, 'Grade')}
                                            className="w-40 mb-2 "
                                        >
                                            <option value=''>Select grade</option>
                                            {uniqueGrades?.map((grade: any) => (
                                                <option key={grade} value={grade}>
                                                    {grade}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='gap-x-5'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Vat</Text>
                                        <select
                                            value={selectedOptions?.['Vat']}
                                            onChange={(e) => handleSelectChange(e, 'Vat')}
                                            className="w-40 "
                                        >
                                            <option value=''>Select vat</option>
                                            <option value="Reverse">
                                                Reverse
                                            </option>
                                            <option value="Marginal">
                                                Marginal
                                            </option>
                                        </select>
                                    </RowAlignCenterSpaced>
                                </Column>
                            </div>
                        </div>
                    )}
                </div>
            </RowAlignCenter>
            {showDropdown && (
                <div
                    className="fixed inset-0 bg-black opacity-30 z-0"
                    onClick={handleButtonClick}
                ></div>
            )}
        </Flex>
    );
};
