import React, { ReactNode, CSSProperties } from 'react'

export type ColorProps = 'blue' | 'green' | 'purple' | 'mid-blue' | 'yellow' | 'white' | 'red' | 'grey'
type WeightProps = 'normal' | 'medium' | 'bold'
type VariantProps = 'caption' | 'label' | 'body' | 'title' | 'heading' | 'head' | 'heading2'

interface TextProps {
    children: ReactNode
    colorV?: ColorProps
    variant?: VariantProps
    weightV?: WeightProps
  style?: CSSProperties
  className?: string; 
}

export const Text: React.FC<TextProps> = ({
    children,
    colorV = 'green',
    variant = 'body',
    weightV = 'normal',
    style,
    className, 
}) => {
    const textClass = getTextClass(colorV, variant, weightV)

    return (
        <div className={`text ${textClass} ${className}`} style={style}>
            {children}
        </div>
    )
}

function getTextClass(
    colorV: ColorProps,
    variant: VariantProps,
    weightV: WeightProps
): string {
    return `text-color-${colorV} text-size-${variant} text-weight-${weightV}`
}

const styles = `

  .text-color-blue {
    color: #112D56;
  }

  .text-color-red {
    color: #800000;
  }

  .text-color-green {
    color: #45948A;
  }
  .text-color-grey {
    color: #767676;
  }

  .text-color-purple {
    color: #5748E6;
  }

  .text-color-mid-blue {
    color: #3B65F6;
  }

  .text-color-yellow {
    color: #E8C877;
  }

  .text-color-white {
    color: #F2F0ED;
  }

  .text-size-caption {
    font-size: 10px;
    font-family: 'Inter Tight', sans-serif;
  }

  .text-size-label {
    font-size: 12px;
    font-family: 'Inter Tight', sans-serif;
  }

  .text-size-body {
    font-size: 14px;
    font-family: 'Inter Tight', sans-serif;
  }

  .text-size-title {
    font-size: 16px;
    font-family: 'Inter Tight', sans-serif;
  }

  .text-size-heading {
    font-size: 20px;
        font-family: 'Archivo Black', sans-serif;

  }
          .text-size-heading2 {
    font-size: 22px;
        font-family: 'Archivo Black', sans-serif;

  }

  .text-size-head {
    font-size: 24px;
     font-family: 'Archivo Black', sans-serif;
  }

  .text-weight-normal {
    font-weight: 400;
  }

  .text-weight-medium {
    font-weight: 500;
  }

  .text-weight-bold {
    font-weight: 600;
  }
`

const styleElement = document.createElement('style')
styleElement.appendChild(document.createTextNode(styles))
document.head.appendChild(styleElement)
