import React, { useCallback, useEffect, useState, useRef } from 'react';
import { MyIcon } from '../../../../base/MyIcon';
import { SearchBar } from '../../../../base/Searchbar';
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { Icon } from '../../../../base/Icon';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { useFetch } from '../../../../../hooks/useFetch';
const baseUrl = process.env.REACT_APP_baseUrl;

type FilterProps = {
    onDataChange: (data: any) => void;
    itemsData: any[];
    refetch: () => void;
};

export const Filter: React.FC<FilterProps> = ({ onDataChange }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [model, setModel] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { getRequest: getItems, data: itemsData } = useFetch(
        `${baseUrl}/stock/get-spare-part-pdf`
    );
    
    useEffect(() => {
        getItems()
    },[getItems])

    const handleButtonClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCloseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation(); // Stop the event propagation to prevent closing the dropdown
        setShowDropdown(false);
    };

    const handleDocumentClick = (e: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setShowDropdown(false);
        }
    };

    const handleOptionsChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {

            const selectedModel = e.target.value;
            setModel(selectedModel);
            const filteredData = itemsData.filter((item) => {
                return item.models.some((model) => model._id === selectedModel);
            });
            onDataChange(filteredData);
        },
        [onDataChange, itemsData]
    );

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showDropdown]);

    const uniqueModelsArray: { _id: string; modelName: string }[] = [];

    if (Array.isArray(itemsData)) {
        itemsData?.forEach((sparepart) => {
            sparepart?.models.forEach((model) => {
                const isModelExist = uniqueModelsArray.some((uniqueModel) => uniqueModel._id === model._id);
                if (!isModelExist) {
                    uniqueModelsArray.push({ _id: model._id, modelName: model.modelName });
                }
            });
        });
    }

    return (
        <Flex className="relative">
            <RowAlignCenter className="gap-x-5">
                {/* <SearchBar width="400px" /> */}
                <div ref={dropdownRef} className="relative w-32">
                    <div
                        onClick={handleButtonClick}
                        className={`cursor-pointer p-2 rounded-lg ${model === '' ? 'bg-b9midblue' : 'bg-b9green'}`}
                    >
                        <div className="flex items-center gap-x-4">
                            <MyIcon name="filter" color="white" />
                            <Text colorV="white" variant="title" weightV="normal">
                                Filter
                            </Text>
                        </div>
                    </div>
                    {showDropdown && (
                        <div className="absolute w-60 top-[calc(100% + 5px)] left-0 mt-2 w-full rounded shadow-lg z-40">
                            <div className=' bg-white rounded-md pt-1'>
                                <div className="flex justify-end pr-1 ">
                                    <span onClick={handleCloseIconClick} className='cursor-pointer' >
                                        <Icon name="cross" color="black" size={18} />
                                    </span>
                                </div>
                                <Column className="p-4" >
                                    <RowAlignCenterSpaced className='justify-center items-center'>
                                        <Text colorV='blue' variant='title' weightV='bold' >Model</Text>
                                        <select
                                            value={model}
                                            onChange={handleOptionsChange}
                                            className=" mb-2 py-1 w-32"
                                        >
                                            <option value="">Select model</option>
                                            {uniqueModelsArray?.map((model) => (
                                                <option key={model._id} value={model._id}>
                                                    {model.modelName}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                </Column>
                            </div>
                        </div>
                    )}
                </div>
            </RowAlignCenter>
            {showDropdown && (
                <div
                    className="fixed inset-0 bg-black opacity-30 z-0"
                    onClick={handleButtonClick}
                ></div>
            )}
        </Flex>
    );
};
