

import React, { useState, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { PrimaryCard } from '../../components/compound/Page/PrimaryCard';
import { Text } from '../../components/base/Text';
import { Flex } from '../../components/layout/Flex';
import { MyIcon } from '../../components/base/MyIcon';
import { useLocation } from 'react-router-dom';

interface BelowNavItem {
    text: string;
    icon: string;
    iconSize?: number;
    to: string;
}

interface BelowNavbarProps {
    initialNavItems: BelowNavItem[];
    heading?: string;
    className?: string;
}

export const BelowNavbar: React.FC<BelowNavbarProps> = ({
    initialNavItems,
    heading,
    className,
}) => {
    const [navItems] = useState<BelowNavItem[]>(initialNavItems);
    const location = useLocation();
    const currentPath = location.pathname;

    const navItemsStyle: CSSProperties = {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
    };

    const navItemStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        // marginBottom: '20px',
        fontSize: '18px',
        cursor: 'pointer',
        color: 'white',
    };

    const navIconStyle: CSSProperties = {
        margin: '0 15px 0 7px',
    };

    const activeItemStyle: CSSProperties = {
        borderLeft: '2px solid #3B65F6',
        transition: 'border 0.3s ease-in-out',
    };

    const isDashboard = currentPath === '/dashboard';

    return (
        <PrimaryCard
            style={{ width:289 }} // Adjust the width based on the dashboard path
            className={`py-5 px-3 ${className} shadow-none !rounded-none`}
        >
            {heading &&  (
                <div className="mb-5">
                    <Text colorV="blue" variant="heading" weightV='normal' >
                        {heading}
                    </Text>
                </div>
            )}

            <ul style={navItemsStyle} className="nav-items">
                {navItems.map((item: BelowNavItem, index: number) => (
                    <li
                        key={index}
                        style={{
                            ...navItemStyle,
                            color: 'white',
                            ...(currentPath === item.to ? activeItemStyle : {}),
                        }}
                        className="nav-item"
                    >
                        <Link
                            to={item.to}
                            style={{
                                textDecoration: 'none',
                                color: 'blue',
                            }}
                        >
                            <Flex>
                                <span
                                    style={{
                                        ...navIconStyle,
                                        color: isDashboard
                                            ? currentPath === item.to
                                                ? 'lgblue'
                                                : 'white'
                                            : 'white', // Hide text and adjust icon color if not on dashboard
                                    }}
                                    className="nav-icon"
                                >
                                    <MyIcon
                                        size={item.iconSize}
                                        name={item?.icon}
                                    />
                                </span>
                                    <Text colorV="blue" variant="title" >
                                        {item.text}
                                    </Text>
                            </Flex>
                        </Link>
                    </li>
                ))}
            </ul>
        </PrimaryCard>
    );
};

