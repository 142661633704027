import React, { useCallback, useEffect, useState, useRef } from 'react';
import { MyIcon } from '../../../base/MyIcon';
import { SearchBar } from '../../../base/Searchbar';
import { Column } from '../../../layout/Col';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenter } from '../../../layout/RowAlignCenter';
import { Text } from '../../../base/Text';
import { Icon } from '../../../base/Icon';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';

type FilterProps = {
    onDataChange: (data: any) => void;
    itemsData: any[];
    refetch: () => void;
};

export const Filter: React.FC<FilterProps> = ({ onDataChange, itemsData,refetch }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});

    const dropdownRef = useRef<HTMLDivElement>(null);
    console.log({ itemsData });
    const handleButtonClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCloseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation(); // Stop the event propagation to prevent closing the dropdown
        setShowDropdown(false);
    };

    const handleDocumentClick = (e: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showDropdown]);


    const uniqueCustomer = Array.from(
        new Set(itemsData?.map(item => item.customer._id))
    )?.map(id => itemsData.find(item => item.customer._id === id).customer);
    console.log({ uniqueCustomer });
    const handleLocalOptionSelect = useCallback((value: string, key: string) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [key]: value
        }));
    }, []);

    const handleOptionsChange = useCallback((options: Record<string, string>) => {
        const filteredData = itemsData.filter(item => {
            if (options['Customer'] && item.customer._id !== options.Customer) {
                return false;
            }
            if (options['Type'] && item.saleType !== options.Type) {
                return false;
            }
            return true;
        });
        onDataChange(filteredData);
    }, [itemsData, onDataChange]);
    
    const handleSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        const { value } = e.target;
        handleLocalOptionSelect(value, key);
        handleOptionsChange({ ...selectedOptions, [key]: value });
    }, [handleLocalOptionSelect, handleOptionsChange, selectedOptions]);

    return (
        <Flex className="relative">
            <RowAlignCenter className="gap-x-5">
                <div ref={dropdownRef} className="relative ">
                    <div
                        onClick={handleButtonClick}
                        className={`cursor-pointer p-2 rounded-lg ${Object.values(selectedOptions).every(value => value === '') ? 'bg-b9midblue' : 'bg-b9green'}`}
                    >
                        <div className="flex items-center gap-x-4 w-28">
                            <MyIcon name="filter" color="white" />
                            <Text colorV="white" variant="title" weightV="normal">
                                Filter
                            </Text>
                        </div>
                    </div>
                    {showDropdown && (
                        <div className="absolute w-72 top-[calc(100% + 5px)] left-0 mt-2 w-full rounded shadow-lg z-40">
                            <div className=' bg-white rounded-md pt-1'>
                                <div className="flex justify-end pr-1 ">
                                    <span onClick={handleCloseIconClick} className='cursor-pointer' >
                                        <Icon name="cross" color="black" size={18} />
                                    </span>
                                </div>
                                <Column className="p-4" >
                                    <RowAlignCenterSpaced className='justify-center'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Customer</Text>
                                        <select
                                             value={selectedOptions?.['Customer']}
                                             onChange={(e) => handleSelectChange(e, 'Customer')}
                                            className=" mb-2 py-1 w-36"
                                        >
                                            <option value="">Select customer</option>
                                            {uniqueCustomer.map((customer) => (
                                                <option key={customer._id} value={customer._id}>
                                                    {customer?.companyName}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='justify-center'>
                                        <Text colorV='blue' variant='body' weightV='bold' >Type of sale</Text>
                                        <select
                                            value={selectedOptions?.['Type']}
                                            onChange={(e) => handleSelectChange(e, 'Type')}
                                            className=" mb-2 py-1 w-36"
                                        >
                                            <option value="">Select Type</option>
                                            <option value="Single sale">
                                                Single sale
                                            </option>
                                            <option value="Bulk sale">
                                                Bulk sale
                                            </option>
                                        </select>
                                    </RowAlignCenterSpaced>
                                </Column>
                            </div>
                        </div>
                    )}
                </div>
            </RowAlignCenter>
            {showDropdown && (
                <div
                    className="fixed inset-0 bg-black opacity-30 z-0"
                    onClick={handleButtonClick}
                ></div>
            )}
        </Flex>
    );
};
