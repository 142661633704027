import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { useFetch } from '../../../../../hooks/useFetch'
import moment from 'moment'
import { EditInventory } from './EditInventoryModel'
import { DeleteInventory } from './DeleteInventoryModel'
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess'
import { useAuth } from '../../../../../utils/auth'
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';
import { generatePDF } from '../../../../shared/GenerateLabel'
import PaginatedItems from './Pagination'
const baseUrl = process.env.REACT_APP_baseUrl;

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

export default function InventoryTable({ itemsData, pageCount, handlePageChange, refetch }: any) {
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>()
    const auth = useAuth();
    const barCode = useRef<HTMLDivElement>(null);
    const addInventoryCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.InHouseInventory && acc.permission.includes(AccessPermissions.Add));
    const viewInventoryCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.InHouseInventory && acc.permission.includes(AccessPermissions.View));
    const editInventoryCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.InHouseInventory && acc.permission.includes(AccessPermissions.Edit));
    let extractedData = []
    if (itemsData?.items) {
        extractedData = itemsData?.items?.map((item) => {
            if (item.sortingEvent?.name === 'warranty_repair') {
                return null; // Skip this item
            }
    
            return {
                Id: item?._id,
                IMEI: item?.imei,
                Date: moment(item?.createdAt).format('DD.MM.YYYY'),
                Model: item?.model?.modelName,
                Storage: item?.storage,
                VAT: item?.vat,
                Status: item?.sortingEvent?.name,
                Code: item?.codes?.toString(),
                // Code: item?.codesAfterFix?.length > 0 ? item?.codesAfterFix?.toString() : item?.codes?.toString(),
                item: item
            };
        }).filter(Boolean); // Remove null values from the array
    }
    
    const columns = ['IMEI', 'Date', 'Model', 'Storage', 'VAT', 'Status', 'Code']

    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setIsEdit(false)
        setIsDelete(false)
        refetch()
    }, [refetch])
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item);
        if (optionValue === 'Edit') {
            setIsEdit(true);
            setIsDelete(false);
        } else if (optionValue === 'Delete') {
            setIsDelete(true);
            setIsEdit(false);
        } else if (optionValue === 'Print') {
            setIsEdit(false);
            setIsDelete(false);
            handlePrintlabelPDF(item);
        }
        setIsModalOpen(true);
    }

const handlePrintlabelPDF = useCallback(async (item) => {
        JsBarcode('#barcode', `${item?.item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 1,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        });
        generatePDF(item,labelsData, barCode)
        // if (item) {
        //     const barcodeSvg = `<svg id="barcode">${barCode.current?.innerHTML}</svg>`;
        //     const docDefinition = {
        //         content: [
        //             labelsData?.labels[1]?.isLabel && {
        //                 svg: barcodeSvg,
        //                 height: 35,
        //                 margin: [0, 0, 0, 5],
        //             },
        //             labelsData?.labels[1]?.isLabel && {
        //                 text: item?.item?.imei, 
        //                 margin: [40, 0, 0, 5],
        //             },
        //             {
        //                 columns: [
        //                     {
        //                         stack: [
        //                             labelsData?.labels[0]?.isLabel && { text: 'Model :    ' + (item?.item?.model?.modelName || ''), margin: [0, 0, 0, 3,], },
        //                             labelsData?.labels[2]?.isLabel && { text: 'Storage :  ' + (item?.item?.storage || ''), margin: [0, 0, 0, 3,],  },
        //                             labelsData?.labels[4]?.isLabel && { text: 'VAT :    ' + (item?.item?.vat || ''), margin: [0, 0, 0, 3,], },
        //                             labelsData?.labels[8]?.isLabel && { text: 'Event :  ' + (item?.item?.sortingEventKey || ''), margin: [0, 0, 0, 3,],  },
        //                             labelsData?.labels[10]?.isLabel && { text: 'Codes :   ' + (item?.item?.codes?.toString() || ''), margin: [0, 0, 0, 0,],  },
        //                         ].filter(Boolean),
        //                         width: 120, // Set the width of this column to take up remaining space
        //                     },
        //                     {
        //                         stack: [
        //                             labelsData?.labels[7]?.isLabel && { text: 'Purchase Order :  ' + (item?.item?.purchaseId?.saleBatchNumber || ''), margin: [0, 0, 0, 3,] },
        //                             labelsData?.labels[3]?.isLabel && { text: 'Color :      ' + (item?.item?.color || ''), margin: [0, 0, 0, 3,] },
        //                             labelsData?.labels[5]?.isLabel && { text: 'Grade :      ' + (item?.item?.grade?.grade || ''), margin: [0, 0, 0, 3,]},
        //                             labelsData?.labels[6]?.isLabel && { text: 'Battery :    ' + (item?.item?.battery || ''), margin: [0, 0, 0, 3,] },
        //                             labelsData?.labels[9]?.isLabel && { text: 'SKU :     ' + (item?.item?.sku?.sku || ''), margin: [0, 0, 0, 3,] },
        //                         ].filter(Boolean),
        //                         width: 110,
        //                     },
        //                 ],


        //             },
        //         ],
        //         styles: {
        //             header: {
        //                 fontSize: 8,
        //                 bold: true
        //               },
        //             tableStyle: {
        //                 margin: [0, 20, 20, 0],
        //             },
        //         },
        //         pageMargins: [10, 0, 10, 0], // Top, Left, Bottom, Right
        //     };

        //     // @ts-ignore
        //     pdfMake.createPdf(docDefinition).print();
        // } else {
        //     toast.error('Error: No data found', {
        //         hideProgressBar: true,
        //     });
        // }
    }, [labelsData]);

    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditInventory item={selectedModel} onClose={closeModal} />;
        } else if (isDelete) {
            return <DeleteInventory item={selectedModel} onClose={closeModal} />;
        }
    }, [isEdit, isDelete, selectedModel, closeModal]);

    return (
        <>
             <Flex className="mt-5 ">
                <PrimaryCard className='w-full'>
                    <Table
                        options={editInventoryCheck ? ['Print', 'Edit', 'Delete'] : ['Print', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            {/* <Pagination
                pageCount={pageCount}
                onPageChange={handlePageChange}
            /> */}
             <PaginatedItems pageCount={pageCount} onPageChange={handlePageChange} />
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
            {isModalOpen && (renderModalComponent()
            )}
        </>
    )
}
